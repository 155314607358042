.wrapper {
  /* position: absolute; */
  /* bottom: 0; */
  /* border:2px solid red; */
  /* margin-bottom: -5px; */
  font-size: smaller;
  width: 100%;
  height: auto;
  text-align: center;
  padding: 0px;
  margin:0;
  color: purple;
}

.title {
  /* border: 2px solid blue; */
  color: purple;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: "Montserrat", sans;
}

