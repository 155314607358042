.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*, *::before, *::after {
    box-sizing: border-box;
}

body {
    margin: 0;
}

main {
    width: 100%;
    max-width: 1000px;
    margin: auto;
    padding: 0.3em;
}

h1 {
    text-align: center;
}


/* add posts form */

form {
    border:3px solid mediumblue;
    padding: 1em;
    border-radius: 10px;
    width: 100%;
    margin: auto;
    max-width: 300px;
}

form h2 {
    text-align: center;
    margin-top: 0;
}

form h3 {
    text-align: center;
    margin-top: 0;
}

.input-container {
    display: flex;   
    margin-bottom: 0.5em;
}

label, input, textarea {
    display: block;
    border-radius: 5px;
    width: 100%;
    font-size: 16px;
    text-align: left;
}

label {
    margin-right: 0.02em;
    width:40%;
    font-weight: bold;
}

textarea {
    
  border:2px solid black;
  resize: vertical;
    height: 150px;
}

.btn-submit {
  background-color: green;
  color: aquamarine;
    font-size: 18px;
    margin: auto;
    display: block;
    border-radius: 5px;
    padding: 0.5em 1em;
    cursor: pointer;
    transition: all, 0.5s;
}

.btn-submit:hover, .btn-submit:focus {
  background-color: lightgreen;
  color: black;
    transform: scale(1.1);
     transition: all, 0.5s;
}

/* Posts */

.posts-container {
  border:3px solid green;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1em;
    padding: 1.0em;
    margin-top: 2em;
}

.posts-container h2 {
  color: green;
    text-align: center;
    margin-top: 0;
    grid-column: 1/-1;
}

.posts-container h3 {
    text-align: center;
    margin-top: 0;
    grid-column: 1/-1;
}

.post-card {
    border: 2px solid green;
    border-radius: 15px;
    padding: 0.5em;
    position: relative;
}

.post-title {
    margin-top: 10px;
}

.post-date {
    margin-top: 0;
}

.post-author {
    margin-top: 10px;
}

.post-body {
    margin-bottom: 50px; 
}

.btn-delete {
  background-color: rgb(206, 86, 86);
    position: absolute;
    color:white;
    bottom: 10px;
    left: 30%;
    right: 30%;
    font-size: 16px;
    margin: auto;
    display: block;
    border-radius: 5px;
    padding: 0.5em 1em;
    cursor: pointer;
    transition: all, 0.5s;
}

.btn-delete:hover, .btn-delete:focus {
  background-color: red;
  color: black;
    transform: scale(1.1);
     transition: all, 0.5s;
}